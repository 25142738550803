<template>
  <auth-layout :title="$t('Register')"
    :loading="loading">

    <template v-slot:body>
      <v-row>
        <v-col>
          <v-text-field
            outlined
            v-model="form.first_name"
            :error-messages="firstError('form.first_name')"
            :label="$t('First Name')"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            outlined
            v-model="form.last_name"
            :error-messages="firstError('form.last_name')"
            :label="$t('Last Name')"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-text-field
          outlined
          v-model="form.email"
          :error-messages="firstError('form.email')"
          label="Email"
          type="email"
      ></v-text-field>
      <v-text-field
          outlined
          v-model="form.password"
          :error-messages="firstError('form.password')"
          :label="$t('Password')"
          :type="showPassword ? 'text' : 'password'"
          :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
          @click:append="showPassword = !showPassword"
      ></v-text-field>
      <vue-tel-input-vuetify
        outlined
        :preferred-countries="['rs', 'ba', 'me', 'hr', 'si', 'de', 'mk', 'ch']"
        :valid-characters-only="true"
        :error-messages="firstError('form.phone')"
        @input="onPhoneInput"
        :hint="phone.number.international"
        persistent-hint
        :label="$t('Phone')"
      />

      <social-auth/>

      <router-link :to="'reset-password'" class="text-decoration-none">
        {{ $t('Reset Password') }}?</router-link>
    </template>

    <template v-slot:footer>
      <router-link to="login">{{ $t('Login') }}</router-link>
      <v-spacer></v-spacer>
      <v-btn
          type="submit"
          @click="submit"
          :loading="loading"
          color="primary"
          class="px-4"
          dark
      >{{$t('Submit Register')}}</v-btn>
    </template>

  </auth-layout>
</template>
<style lang="scss">
  .vue-tel-input-vuetify .country-code .v-input__control > .v-input__slot:before{
    border:none !important;
  }
</style>
<script>
import { validationMixin } from 'vuelidate';
import { required, minLength, email } from 'vuelidate/lib/validators';
import axios from 'axios';
import SocialAuth from './components/SocialAuth.vue';
import FormValidationMixin from '../../lib/FormValidationMixin';

export default {
  components: {
    SocialAuth,
  },
  mixins: [validationMixin, FormValidationMixin],
  validations: {
    form: {
      first_name: {
        required,
        txtMinLen: minLength(2),
      },
      last_name: {
        required,
        txtMinLen: minLength(2),
      },
      email: {
        email,
        required,
        txtMinLen: minLength(2),
      },
      password: {
        required,
        txtMinLen: minLength(2),
      },
      phone: {
        validPhoneNumber() {
          return this.phone.valid === true;
        },
      },
    },
  },
  data() {
    return {
      phone: {
        number: {},
      },
      form: {
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        phone: '',
      },
      loading: false,
      showPassword: false,
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.loading = true;
      axios
        .post('register', {
          ...this.form,
        })
        .then(({ data: loginResponse }) => {
          this.$store.dispatch('loginUser', loginResponse);
          // ToDO ako je ukljucen verifja samo pokazi da pogleda email.
        })
        .catch((error) => {
          this.$store.dispatch('setErrors', error.response.data.errors);
          this.loading = false;
        });
    },
    onPhoneInput(formattedNumber, phone) {
      this.phone = phone;
      this.form.phone = phone.number.e164;
    },
  },
  destroyed() {
    this.$store.dispatch('clearErrors');
  },
};
</script>
